<template>
  <div class="w ww cp_det_top">
    <div class="bread">
      {{this.$t('position')}} > <a @click="goIndex">{{this.$t('homePage')}}</a> > <a>{{this.$route.query.exp2}}</a> >
      <a>{{ proInfo.productName || "-" }}</a>
    </div>
      <h3 class="title">
        {{ proInfo.productName }}
      </h3>
    <div class="pc_fl cp_det_lf">
      <div class="cp_det_img">
        <div class="fl swiper-container gallery-top">
          <div class="swiper-wrapper">
            <div class="swiper-slide" >
              <slide @getIndex ="getIndex">
                <slide-item v-for="(item,index) in pictureList" :key="index">
                  <el-image :src="pictrueUrl+item.filePath" :preview-src-list="srcList"> </el-image>
                </slide-item>
              </slide>
              <div class="swiper-memo">{{memo}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="pc_fr cp_det_rt">
      <div class="cp_det_rt_3">
        <table width="100%">
          <tr>
            <th>{{this.$t('ArticleNo')}}</th>
            <th>{{this.$t('Specifications')}}</th>
            <th>{{this.$t('stock')}}</th>
            <th>{{this.$t('price')}}</th>
             <th>{{this.$t('Promotion')}}</th>
            <th>{{this.$t('OrderQuantity')}}</th>
          </tr>
          <tr v-for="(item, index) in proDtList" :key="index">
            <td>
              {{ item.specsCode }}
            </td>
            <td>{{ item.specsName || "-" }}</td>

            <td>{{item.specsStock===0?$t('inStock'):item.specsStock===1?$t('outOfStock'):item.specsStock===2?'In Stock':item.specsStock===3?'Out of Stock':'-'}}</td>
            <td>
              {{item.specsPrice>0?item.specsForeign == 1? "$" : "￥":''}} {{item.specsPrice == 0? $t("Inquiry"):item.specsPrice || "-"}}
            </td>

            <td class="specsPromotionprice" v-if="item.specsPromotionprice == 0 || item.specsPromotionprice == '询价'">
              {{ $t("Inquiry") }}
            </td>
            <td class="specsPromotionprice" v-else>
              {{item.specsPromotionprice>0? item.specsForeign == 1 ? "$" : "￥":''}}{{ item.specsPromotionprice || '-'}}
            </td>

            <td>
              <div class="gw_num">
                <em
                  class="jian"
                  @click="deIncrease(item, index, 'de')"
                  :disabled="disabled"
                  >-</em
                >
                <input
                  type="number"
                  v-model="item.count"
                  @blur="proNumBlur(index)"
                />
                <em class="add" @click="deIncrease(item, index, 'add')">+</em>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="cp_det_rt_4">
        <a @click="nowBuy" class="a">{{this.$t('BuyNow')}}</a>
        <a class="b" @click="goBuyCart('cart')"
          ><img src="@/assets/img/27.png" />{{this.$t('addCard')}}</a
        >
        <a class="c"  @click="share"><img src="@/assets/img/28.png"/>{{this.$t('share')}}</a>
        <a class="c" @click.stop="collect(proInfo.owid, proInfo.isCollection)" >
           <img :src=" proInfo.isCollection == 1 ? require('@/assets/img/29-1.png') : require('@/assets/img/29.png')"/>
                        {{ proInfo.isCollection == 1 ? $t("collected") : $t("collect") }}
          </a >
        <div class="clear"></div>
      </div>
      <div class="cp_det_rt_5">
        <ul class="left">
          <li>
            <img src="@/assets/img/88.png" alt="">
            <a>{{this.$t('office')}}</a>
          </li>
          <li>
            <img src="@/assets/img/89.png" alt="">
            <a>{{this.$t('commonProblem')}}</a>
          </li>
          <li>
            <img src="@/assets/img/90.png" alt="">
            <!-- <a>{{this.$t('technicalSupport')}}</a> -->
             <el-dropdown trigger="click" placement="bottom">
                  <span>{{this.$t('technicalSupport')}}<i class="el-icon-arrow-down el-icon--right"></i> </span>
                  <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item  v-for="v in syznList" :key="v.owid">
                    <a :href="pictrueUrl+v.filePath" target="_blank">{{v.fileName}}</a>
                  </el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
          </li>
          <li>
             <img src="@/assets/img/91.png" alt="">
               <el-dropdown trigger="click" placement="bottom">
                  <span>{{this.$t('instructions')}}<i class="el-icon-arrow-down el-icon--right"></i> </span>
                  <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item  v-for="v in fileList" :key="v.owid">
                    <a :href="pictrueUrl+v.filePath" target="_blank">{{v.fileLabel}}</a>
                  </el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
          </li>
        </ul>
        <div class="right">
          <div class="tip">{{this.$t('ContactPay')}}</div>
          <div class="content_de" v-for="(v,i) in contactMethods" :key="i">
            <p>{{v.dicVal2}}：{{v.dicVal4}}</p>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="clear"></div>
    <div class="w ww cp_det_bot">
      <div class="pc_fr cp_det_tab">
        <ul class="zx_t">
          <li :class="{ active: currentIndex == 1 }" @click="showIsHide(1)">
            {{this.$t('ProductDes')}}
          </li>
          <li :class="{ active: currentIndex == 2 }" @click="showIsHide(2)">
            {{this.$t('literature')}} （{{refNum || 0}}）
          </li>
        </ul>
        <ul class="zx_c">
          <li class="thisclass" v-show="currentIndex == 1">
            <div class="clear"></div>
            <div class="product-attribute" v-if="productDesc">
              <p v-html="productDesc">{{ productDesc }}</p>
            </div>
          </li>
          <li class="thisclass" v-show="currentIndex == 2">
            <div class="clear"></div>
            <div class="product-attribute" v-if="productJj">
              <p  v-html="productJj">{{ productJj }}</p>
            </div>
          </li>
        </ul>
      </div>
       <!-- 相关产品 -->
      <div class="pc_fl cp_det_jx" v-if="this.rmdProArr.backCode !== 0">
        <h2>
          {{this.$t('similarProduct')}}
        </h2>
        <ul>
          <li
            v-for="(v, i) in rmdProArr"
            :key="i"
            @click="goDetail(v.productRefOwid)"
          >
            <a>
              <div class="cp_jx_img"><img :src="pictrueUrl+v.productPic"/></div>
              <div class="cp_jx_txt">
                <h3 class="ellipsis2">{{ v.productName || "-" }}</h3>
                <span>{{$t('no')}}：{{v.productCode}}</span>
                 <p>{{ v.specsForeign === 1 ? "$" : "￥"}}{{ v.promotionPrice || "-"}}</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
    <el-dialog
  title="分享连接"
  :visible.sync="dialogVisible"
  width="40%"
  :before-close="handleClose">
    <span>{{shareLink}}</span>
  <span slot="footer" class="dialog-footer">
  </span>
</el-dialog>
  </div>
</template>

<script>
import {
  productDetail,
  getPictureList,
  getSpecsList,
  rdmPro,
  getProductAttributeList,
  addCartBatch,
  takeClt,
  cancelClct,
  getProductSimilar,
  contactBuy
} from "./model";
import { goIndex, showLayer, isHasLogin, ifCountZero } from "@/utils/com";
import {visit} from '../personalInfo/myTracks/model'
import { Slide,SlideItem} from '@/components/slide/index'
import { IMGURL } from "@/utils/constant";
let pageSize = 5;
export default {
  data() {
    return {
      pictrueUrl: '',
      // 产品详细信息
      proInfo: "",
      // 图片列表
      pictureList: "",
      // 产品简介
      productJj: "",
      // 产品描述
      productDesc: "",
      // 商品标签
      productLabel: "",
      // 产品清单
      proDtList: "",
      productId: {
        owid: "",
      },
      disabled: false,
      currentIndex: "1",
      //推荐产品
      rmdProArr: [],
      pageNo: 1,
      rmdTotalPage: 0,
      basicAttribute: "",
      // 相关文档
      fileList: "",
      // 实验手册
      syznList:'',
      // fileType: '',
      dialogVisible:false,
      shareLink:'',
      memo:'',
      srcList: [],
      url:'',
      isForeign:0,
      nowBuyProduct:[],
      contactMethods:'',
      refNum:0
    };
  },
  components:{
    Slide,
    SlideItem
  },
  computed:{

    content(){
      console.log(this.productJj);
      // let content ={};
      // for(let i=0;i<this.productJj.length;i++){
      //   let key=this.productJj[i];
      //   if(content[key]){
      //     content[key]++
      //   }else {
      //      content[key] = 1;
      //   }
      // }
      // return content['[]'];
    }
  },
  created() {
    this.shareLink = window.location.href
    this.pictrueUrl = IMGURL;
    this.productId.owid = this.$route.query.proId || "";
    //商品详情
    productDetail(this.productId).then((res) => {
      this.refNum = res.refNum
      console.log(res);
      this.proInfo = res;
      // 创建浏览记录
        visit({bizOwid:res.owid})
      this.productDesc = res.productDesc;
      this.fileList = res.fileList || [];
      this.syznList = res.syznList || []
      if (res.productJj) {
        this.productJj = res.productJj;
      }
      if (res.productLabel) {
        this.productLabel = res.productLabel.split(",");
      }
    }),
      //商品图片
      getPictureList(this.productId).then((res) => {
        this.pictureList = res;
        this.memo = this.pictureList[0].memo
        // 将商品图片链接保存在数组中
        this.pictureList.forEach((v,i)=>{
          this.srcList.push(this.pictrueUrl+v.filePath)
        })
      }),
      //获取规格
      getSpecsList(this.productId).then((res) => {
        this.proDtList = res;
      });

    getProductAttributeList(this.productId).then((res) => {
      this.basicAttribute = res;
    });

    // 相似产品
    getProductSimilar({owid:this.productId.owid,isForeign:this.isForeign}).then(res=>{
      this.rmdProArr = res;
    })
    // 联系购买
    this.contact()
  },
  methods: {
    goBuyCart(type) {
      if (isHasLogin()) {
        if (!ifCountZero(this.proDtList)) {
          showLayer("toast", "请至少选择一件");
          return;
        }
        type == "buy" && this.addCartApi("goPage");
        type == "cart" && this.addCartApi("toast");
      } else {
        showLayer("toast", "请先登录");
      }
    },

    //添加购物车接口
    async addCartApi(type) {
      let {
        categoryRefOwid,
        categoryName,
        specsPromotionprice,
        owid,
        productName,
        secondName,
      } = this.proInfo;
      let params = [];
      let obj = {};

      //获取当前产品的信息
      let proInfo = {
        productRefOwid: owid,
        specsPromotionprice,
        categoryRefOwid, //商品分类
        categoryName,
        productName,
        secondName,
      };

      //遍历规格
      this.proDtList.forEach((v) => {
        if (v.count > 0) {
          obj = {
            itemQuantity: v.count,
            itemPrice: v.specsPromotionprice,
            specsRefOwid: v.owid,
            specsName: v.specsName,
            productPic : v.productPic
          };
          params.push({ ...proInfo, ...obj });
        }
      });

      //批量加入购物车
      let res = await addCartBatch({ productList: params });
      if (res) {
        if( type == "toast"){
          showLayer("success", "添加成功，在购物车等您");
        }
      }
    },
    nowBuy(){
      if (isHasLogin()) {
        if (!ifCountZero(this.proDtList)) {
          showLayer("toast", "请至少选择一件");
          return;
        }
        // type == "buy" && this.addCartApi("goPage");
        // type == "cart" && this.addCartApi("toast");
      } else {
        showLayer("toast", "请先登录");
      }

      //     this.proDtList.forEach((v) => {
      //   if (v.count > 0) {
      //     let norms = {
      //       itemQuantity: v.count,
      //       itemPrice: v.specsPromotionprice,
      //       specsRefOwid: v.owid,
      //       specsName: v.specsName,
      //       productPic : v.productPic
      //     };
      //     this.nowBuyProduct.push({...norms });
      //   }

      // });
    this.nowBuyProduct= this.proDtList.filter(item=>{
        return item.count > 0
      })
      this.$router.push({path:"/profile/buyNow",query:{params:JSON.stringify(this.nowBuyProduct)}});
    },
    //点击返回首页
    goIndex() {
      goIndex(this);
    },

    //填写商品失去焦点 临界值处理
    proNumBlur(index) {
      let { count = 0, specsStock = 0 } = this.proDtList[index];
      if (count > specsStock) {
        this.proDtList[index].count = specsStock;
      }
      if (count < 0 || !count) {
        this.proDtList[index].count = 0;
      }
    },

    //加减商品
    deIncrease(v, index, type) {
      let { count = 0, specsStock = 0 } = this.proDtList[index];
      //增加
      switch (type) {
        case "add":
          // if (count < specsStock + 1) {
            this.proDtList[index].count = count + 1;
          // } else {
          //   showLayer("toast", "不能再多了");
          // }
          break;

        case "de":
          if (count > 0) {
            this.proDtList[index].count = count - 1;
          } else {
            showLayer("toast", "不能再少了");
          }
          break;
      }
    },

    // async getRmd() {
    //   let { records, totalPage, currentIndex } = await rdmPro({
    //     pageSize,
    //     pageNo: this.pageNo++,
    //     type: 5,
    //   });
    //   this.rmdProArr = records;
    //   this.rmdTotalPage = totalPage;
    //   if (totalPage == currentIndex) {
    //     this.pageNo = 1;
    //   }
    // },

    //精品推荐下一页
    // goNextRmd() {
    //   this.getRmd();
    // },

    showIsHide(index) {
      this.currentIndex = index;
    },

    //收藏
    async collect(id, isCollection) {
      if (isCollection == 1) {
        //已經收藏取消收藏
        let result = await cancelClct({ collectList: id });
        result &&(this.proInfo.isCollection = 2) &&
          showLayer("toast", this.$t("calCollect"));
      } else {
        //收藏
        let result = await takeClt({ collectList: id });
        result &&
          (this.proInfo.isCollection = 1) &&
          showLayer("toast", this.$t("clectSuccess"));
      }
    },
    goDetail(owid) {
      this.productId.owid = owid;
      this.$router.push({ path: "productDetails", query: {proId: owid }});
    },
    handleClose(done){
       done();
    },
    share(){
      this.dialogVisible = true
    },
    onDplOver(event){
    let ul = event.currentTarget.childNodes[1];
    ul.style.display = "block";
    },
    onDplOut(event){
    let ul = event.currentTarget.childNodes[1];
    ul.style.display = "none";
},
// 动态获取图片memo
    getIndex(newVal){
      if(newVal<=this.pictureList.length && newVal-1>=0){
          this.memo = this.pictureList[newVal-1].memo;
      }
    },
    enlarge(url){
      this.url = url;
    },
    // 联系购买
    contact(){
      contactBuy({dicType:20008}).then(res=>{
       this.contactMethods = res;
      })
    }
  }
};
</script>
<style scoped>
.specsPromotionprice {
  color: #f55050;
  font-weight: 700;
}
</style>
