<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 16:05:57
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-10 16:38:18
-->
<template>
  <div class="product-details">
      <screen/>
      <product-attribute/>
  </div>
</template>

<script>
import Screen from '@/views/index/Screen.vue'
import ProductAttribute from './ProductAttribute.vue'
export default {
    components:{
        Screen,
        ProductAttribute,
    }
}
</script>
        

<style>

</style>