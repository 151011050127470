/*
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-18 16:50:56
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-21 22:16:29
 */
import {
  Product
} from '@/api/index'
import {
  dealUrlHttp,
  isHasLogin,
  showLayer
} from '@/utils/com'
export async function productDetail(data) {
  let res = await Product.productDetail(data);
  return res;
}

//展示的图片
export async function getPictureList(data) {
  let res = await Product.getPictureList(data);
  res && Array.isArray(res) && res.forEach((v) => {
    v.picPath = dealUrlHttp(v.picPath)
  })
  return res || [];
}

//规格清单
export async function getSpecsList(data) {
  let res = await Product.getSpecsList(data);
  res && Array.isArray(res) && res.forEach((v, i) => {
    v.count = 0;
  })
  return res;
}

export async function getProductAttributeList(data) {
  let res = await Product.getProductAttributeList(data)
  return res;
}

//产品推荐
export async function rdmPro(data) {
  let res = await Product.rmdProducts(data);
  if (res && res.records && Array.isArray(res.records)) {
    res.records.forEach((e) => {
      e.productPic = dealUrlHttp(e.productPic);
    });
    return res;
  }
}

// 批量加入购物车
export async function addCartBatch(data) {
  let res = await Product.addCartBatch(data)
  return res;
}

//收藏產品
export async function takeClt(payload) {
  if (isHasLogin()) {
    let res = await Product.takeCollect(payload);
    return res;
  } else {
    showLayer('toast', '请先登录');
  }
}

//取消收藏
export async function cancelClct(payload) {
  if (isHasLogin()) {
    let res = await Product.caclCollect(payload);
    return res;
  } else {
    showLayer('toast', '请先登录');
  }
}

//相似产品
export async function getProductSimilar(data){
  let res = await Product.getProductSimilar(data)
  return res;
}

export async function contactBuy(data){
  let res = await Product.contactBuy(data)
  return res;
}