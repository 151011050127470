import { Personal,Com } from '@/api/index'

export async function logList(data){
    let res = await Personal.logList(data)
    return res;
}

// 浏览记录
export async function visit(data){
    let res = await Com.visit(data)
    return res;
}